import axios from "axios";

// const API_URL = "http://localhost:8080/v1/";
const API_URL = "https://api.cropmonitor.co.in/v1/";

const register = (name, email, mobile, address, district, pincode, state, block, village, aadharNumber, device_id, password) => {
  return axios.post(API_URL + "registration", {
    name,
    email,
    mobile,
    aadhar:aadharNumber,
    village,
    block,
    address,
    district,
    pincode,
    state,
    device_id,
    password,
  });
};

const login = (user, password) => {
  return axios
    .post(API_URL + "login", {
      user,
      password,
    })
    .then((response) => {
      console.log(response)
      if (response.data.data.user_data) {
        console.log(response.data.data.user_data.updated_at)
        localStorage.setItem("user_data", JSON.stringify(response.data.data.user_data));
        localStorage.setItem("user_data_updated_at", response.data.data.user_data.crop ? response.data.data.user_data.crop.updated_at : "");
      }

      if (response.data.data.token) {
        console.log('2')
        localStorage.setItem("token", response.data.data.token);
      }

      return response.data;
    });
};

// const login = (username, password) => {
//   let input = username;
//   if(input.includes("@"))
//     input = username.split("@")[0];
//   else
//     input=username;

//   return new Promise((resolve, reject) => {
//     // Assuming you have a `users` array with user data
//     axios.get(API_URL + "users")
//       .then(response => {
//         console.log(response, "responseresponse")
//         const users = response.data;
//         const user = users.find(item => (item.username === input || item.mobile === input) && item.password === password);

//         if (user) {
//           localStorage.setItem("user", JSON.stringify(user));
//           resolve(user);
//         } else {
//           reject(new Error("Invalid username or password"));
//         }
//       })
//       .catch(error => {
//         reject(error);
//       });
//   });
// };

const logout = async () => {
  localStorage.removeItem("user_data");
  localStorage.removeItem("token");
  return true;
};

const saveUserCorp = (selectedCorp) => {
  const currentUser = getCurrentUser();

    // Update the corp field in the currentUser object
    currentUser.corp = selectedCorp;

    // You can optionally save the updated user data to localStorage or any other storage mechanism
    // For example, to save it to localStorage:
    localStorage.setItem('user_data', JSON.stringify(currentUser));

  // return axios.post(`${API_URL}users/${userId}/corp`, { corp: selectedCorp });
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user_data"));
};

const AuthService = {
  register,
  login,
  logout,
  getCurrentUser,
  saveUserCorp
}

export default AuthService;
