import React from "react";
import GaugeChart from 'react-gauge-chart';

const Gauge = ({ value, max, label }) => {
  // Calculate the percentage value
  const percentage = value / max;

  return (
    <div style={{ width: '100%', textAlign: 'center' }}>
      <GaugeChart
        id="gauge-chart1"
        percent={percentage}
        cornerRadius={2}
        arcWidth={0.3}
        colors={['#5BE12C', '#F5CD19', '#EA4228']}
        textColor="#333"
        needleColor="#345243" 
      />
    </div>
  );
};

export default Gauge;
