import React, { useState, useRef } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import TextArea from "react-validation/build/textarea";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";
import { useNavigate } from "react-router-dom";
import image from '../image/image2.png';
import AuthService from "../services/auth.service";
import Csir from '../image/CSIR-Logo.jpg';

const required = (value) => {
  if (!value) {
    return (
      <div className="invalid-feedback d-block">
        This field is required!
      </div>
    );
  }
};

const validEmail = (value) => {
  if (!isEmail(value)) {
    return (
      <div className="invalid-feedback d-block">
        This is not a valid email.
      </div>
    );
  }
};

const vMobile = (value) => {
  if (value.length < 10 || value.length > 10 ) {
    return (
      <div className="invalid-feedback d-block">
        The mobile must be 10 numbers.
      </div>
    );
  }
};

const vname = (value) => {
  if ((value.length < 3 || value.length > 20)) {
    return (
      <div className="invalid-feedback d-block">
        The name must be between 3 and 20 characters.
      </div>
    );
  }
   else if(!value.match(/^[a-zA-Z ]+$/g)) {
    return (
      <div className="invalid-feedback d-block">
       Please enter valid name
      </div>
    );
  }
};

const vpassword = (value) => {
  if (value.length < 8 || value.length > 40) {
    return (
      <div className="invalid-feedback d-block">
        The password must be between 8 and 40 characters.
      </div>
    );
  } else if (!value.match(/[a-z]/g)) {
    return (
      <div className="invalid-feedback d-block">
          Please enter at least a lower character.
      </div>
    );
  } else if (!value.match(/[A-Z]/g)) { 
    return (
      <div className="invalid-feedback d-block">
          Please enter at least an upper character.
      </div>
    );
  } else if (!value.match(/[0-9]/g)) {
    return (
      <div className="invalid-feedback d-block">
          Please enter at least one digit.
      </div>
    );
  }
};

const Register = (props) => {
  const form = useRef();
  const checkBtn = useRef();

  const [mobile, setMobile] = useState("");
  const [pin, setPin] = useState("");
  const [deviceId, setDeviceId] = useState("");
  const [state, setState] = useState("");
  const [district, setDistrict] = useState("");
  const [block, setBlock] = useState("");
  const [village, setVillage] = useState("");
  const [aadharNumber, setAadharNumber] = useState("");
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [successful, setSuccessful] = useState(false);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  
  const onChangeMobile = (e) => {
    const mobile = e.target.value;
    setMobile(mobile);
  };

  const onChangeName = (e) => {
    const name = e.target.value;
    setName(name);
  };

  const onChangeAddress = (e) => {
    const address = e.target.value;
    setAddress(address);
  };

  const onChangeDistrict = (e) => {
    const district = e.target.value;
    setDistrict(district);
  };

  const onChangePin = (e) => {
    const pin = e.target.value;
    setPin(pin);
  };

  const onChangeState = (e) => {
    const state = e.target.value;
    setState(state);
  };

  const onChangeDeviceId = (e) => {
    const device = e.target.value;
    setDeviceId(device);
  };

  const onChangeBlock = (e) => {
    const block = e.target.value;
    setBlock(block);
  };

  const onChangeVillage = (e) => {
    const village = e.target.value;
    setVillage(village);
  };

  const onChangeAadharNumber = (e) => {
    const aadharNumber = e.target.value;
    setAadharNumber(aadharNumber);
  };

  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const handleRegister = (e) => {
    e.preventDefault();

    setMessage("");
    setSuccessful(false);

    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      AuthService.register(name, email, mobile, address, district, pin, state, block, village, aadharNumber, deviceId, password).then(
        (response) => {
          setMessage("User Successfully registered. Redirecting to the login page")
          setSuccessful(true);
          setTimeout(()=>{
          navigate("/login");
          window.location.reload();
          }, 2000)
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          setMessage(resMessage);
          setSuccessful(false);
        }
      );
    }
  };

  return (
    <div className="col-md-12">
      <div className="card" style={{maxWidth: "80%"}}>
        <img
          src={Csir}
          alt="CSIR Logo"
          className="profile-img-card"
        />

        <Form onSubmit={handleRegister} ref={form}>
          {!successful && (
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="name">Name / নাম</label>
                  <Input
                    type="text"
                    className="form-control"
                    name="name"
                    value={name}
                    onChange={onChangeName}
                    validations={[required, vname]}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="email">Email / ইমেইল</label>
                  <Input
                    type="text"
                    className="form-control"
                    name="email"
                    value={email}
                    onChange={onChangeEmail}
                    validations={[required, validEmail]}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="mobile">Mobile / মোবাইল</label>
                  <Input
                    type="text"
                    className="form-control"
                    name="mobile"
                    value={mobile}
                    onChange={onChangeMobile}
                    validations={[required, vMobile]}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="address">Address / ঠিকানা</label>
                  <TextArea
                    type="text"
                    className="form-control"
                    name="address"
                    value={address}
                    onChange={onChangeAddress}
                    validations={[required]}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="district">District / জেলা</label>
                  <Input
                    type="text"
                    className="form-control"
                    name="district"
                    value={district}
                    onChange={onChangeDistrict}
                    validations={[required]}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="block">Block / ব্লক</label>
                  <Input
                    type="text"
                    className="form-control"
                    name="block"
                    value={block}
                    onChange={onChangeBlock}
                    validations={[required]}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="village">Village / গ্রাম</label>
                  <Input
                    type="text"
                    className="form-control"
                    name="village"
                    value={village}
                    onChange={onChangeVillage}
                    validations={[required]}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="aadharNumber">Aadhar Number / আধার নম্বর</label>
                  <Input
                    type="text"
                    className="form-control"
                    name="aadharNumber"
                    value={aadharNumber}
                    onChange={onChangeAadharNumber}
                    validations={[required]}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="pin">Pin / পিন</label>
                  <Input
                    type="text"
                    className="form-control"
                    name="pin"
                    value={pin}
                    onChange={onChangePin}
                    validations={[required]}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="state">State / রাজ্য</label>
                  <Input
                    type="text"
                    className="form-control"
                    name="state"
                    value={state}
                    onChange={onChangeState}
                    validations={[required]}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="deviceid">Device Id / যন্ত্রের আইডি</label>
                  <Input
                    type="text"
                    className="form-control"
                    name="deviceid"
                    value={deviceId}
                    onChange={onChangeDeviceId}
                    validations={[required]}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="password">Password / পাসওয়ার্ড</label>
                  <Input
                    type="password"
                    className="form-control"
                    name="password"
                    value={password}
                    onChange={onChangePassword}
                    validations={[required, vpassword]}
                  />
                </div>
              </div>
              <div className="form-group col-md-6" style={{margin:"0 auto"}}>
                <button className="btn btn-primary btn-block">Sign Up / নথিভুক্তি করুন</button>
              </div>
            </div>
          )}

          {message && (
            <div className="form-group">
              <div
                className={
                  successful ? "alert alert-success" : "alert alert-danger"
                }
                role="alert"
              >
                {message}
              </div>
            </div>
          )}
          <CheckButton style={{ display: "none" }} ref={checkBtn} />
        </Form>
      </div>
    </div>
  );
};

export default Register;
