import React, { useState, useEffect } from "react";
import { GatewayService, UserService, PumpService } from '../services/user.service';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Box,
  CircularProgress,
  Typography
} from "@mui/material";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { useNavigate } from "react-router-dom";
import WarningIcon from '@material-ui/icons/Warning';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const useStyles = makeStyles((theme) => ({
  tableHeader: {
    backgroundColor: '#1976d2 !important',
    color: 'white !important',
    fontWeight: 'bold !important',
    position: 'sticky',
    top: 0,
    zIndex: 1,
  },
  tableCell: {
    padding: theme.spacing(2),
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
  },
  tableRow: {
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
  tableContainer: {
    maxHeight: 'calc(100vh - 160px)', // Adjust the max height as needed
    overflowY: 'auto',
  },
  alertRed: {
    color: 'red !important',
    fontWeight: 'bold !important',
  },
  alertOrange: {
    color: 'orange !important',
    fontWeight: 'bold !important',
  },
  alertYellow: {
    color: 'yellow !important',
    fontWeight: 'bold !important',
  },
  alertGreen: {
    color: 'green !important',
    fontWeight: 'bold !important',
  },
  tableContainer1: {
    maxHeight: 'calc(100vh - 160px)', // Adjust the max height as needed
    overflowY: 'auto',
    marginBottom: '1rem'
  },
  chartBox: {
    marginBottom: theme.spacing(4),
    width: '100%', // Added for mobile view
    [theme.breakpoints.up('md')]: {
      width: '50%', // Two charts per row on desktop
    },
  },
}));

const Home = () => {
  const [content, setContent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [gatewayContent, setGatewayContent] = useState("");
  const [alertContent, setAlertContent] = useState("");
  const [alertLoading, setAlertLoading] = useState(true);
  const [gatewayLoading, gatewaySetLoading] = useState(true);
  const classes = useStyles();
  const isDesktop = useMediaQuery('(min-width:960px)');

  const navigate = useNavigate();

  const getAlertColor = (alert) => {
    switch (alert) {
      case 'Red':
        return classes.alertRed;
      case 'Orange':
        return classes.alertOrange;
      case 'Yellow':
        return classes.alertYellow;
      case 'Green':
        return classes.alertGreen;
      default:
        return '';
    }
  };

  useEffect(() => {
    if(!localStorage.getItem("token")){
      navigate("/login");
    }
    else{
      UserService.getPublicContent().then(
        (response) => {
          setContent(response.data.data);
          setLoading(false);
        },
        (error) => {
          const _content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
          
          toast.error(error.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: false
          });
  
          setContent(_content);
          setLoading(false);
        }
      );
      GatewayService.getGatewayData().then(
        (response) => {
          setGatewayContent(response.data);
          gatewaySetLoading(false);
        },
        (error) => {
          const _content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
            setGatewayContent(_content);
            gatewaySetLoading(false);
  
          toast.error(error.response.data.message, {
            position: toast.POSITION.TOP_LEFT,
            autoClose: false
          });
      });
    }
  }, []);

  const controlPump = (state) => {
    PumpService.controlPump(state).then(
      (response) => {
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: false
        });
      },
      (error) => {
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
        
        toast.error(error.response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: false
        });
      });
    console.log(`Turning Pump: ${state}`);
  };

  const syncGateway = () => {
    GatewayService.getGatewayData().then(
      (response) => {
        setGatewayContent(response.data);
        gatewaySetLoading(false);

        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: false
        });
      },
      (error) => {
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
          setGatewayContent(_content);
          gatewaySetLoading(false);
        
        toast.error(error.response.data.message, {
          position: toast.POSITION.TOP_LEFT,
          autoClose: false
        });
    });
    UserService.getPublicContent().then(
      (response) => {
        setContent(response.data.data);
        setLoading(false);
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: false
        });
      },
      (error) => {
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
        
        toast.error(error.response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: false
        });

        setContent(_content);
        setLoading(false);
      }
    );
    console.log("Syncing gateway...");
  };

  const convertDate = (utcString) => {
    const date = new Date(utcString);

    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  };
  
  const formatDateTime = (utcString) => {
    const date = new Date(utcString);
  
    // Get day, month, and year components
    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const year = date.getUTCFullYear();
  
    // Get time components
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    const seconds = String(date.getUTCSeconds()).padStart(2, '0');
  
    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
  };

  return (
    <Box p={2}>
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Box display="flex" alignItems="center" justifyContent="space-between" marginBottom={2}>
            <Box>
              <Button
                variant="contained"
                onClick={() => controlPump('on')}
                sx={{ marginRight: isDesktop ? 2 : 0 , marginBottom: isDesktop ? 0 : 2, width: '8rem' }}
              >
                Pump On / পাম্প চালু
              </Button>
              <Button
                variant="contained"
                onClick={() => controlPump('off')}
                sx={{ marginRight: isDesktop ? 2 : 0 , marginBottom: isDesktop ? 0 : 2, width: '8rem' }}
              >
                Pump Off / পাম্প বন্ধ
              </Button>
              {isDesktop && (
                <Button
                  variant="contained"
                  onClick={syncGateway}
                >
                  Refresh / রিফ্রেশ
                </Button>
              )}
            </Box>
            {!isDesktop && (
              <Button
                variant="contained"
                onClick={syncGateway}
                sx={{ marginTop: isDesktop ? 0 : 1.5}}
              >
                Refresh / রিফ্রেশ
              </Button>
            )}
          </Box>

          <TableContainer component={Paper} className={classes.tableContainer1}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableHeader}>Gateway ID / গেটওয়ের আইডি</TableCell>
                  <TableCell className={classes.tableHeader}>Battery Voltage / ব্যাটারির ভোল্টেজ</TableCell>
                  <TableCell className={classes.tableHeader}>Pump Status / পাম্পের স্থিতি</TableCell>
                  <TableCell className={classes.tableHeader}>Last Seen / শেষ দেখাগেছিল</TableCell>
                </TableRow>
              </TableHead>
              {typeof gatewayContent.data === 'object' && Object.keys(gatewayContent.data).length !== 0 ? <TableBody>
                <TableRow>
                  <TableCell className={classes.tableCell}>{gatewayContent.data.gateway_id}</TableCell>
                  <TableCell className={classes.tableCell}>{gatewayContent.data.battery_voltage}</TableCell>
                  <TableCell className={classes.tableCell}>{gatewayContent.data.pump_status}</TableCell>
                  <TableCell className={classes.tableCell}>{new Date(gatewayContent.data.created_on).toLocaleString(undefined, { dateStyle: 'short', timeStyle: 'short' })}</TableCell>
                </TableRow>
              </TableBody> : null }
            </Table>
          </TableContainer>

          <TableContainer component={Paper} className={classes.tableContainer}>
            <Table stickyHeader >
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableHeader}>Node ID / নোডের আইডি</TableCell>
                  <TableCell className={classes.tableHeader}>Soil Temperature 1 / মাটির তাপমাত্রা ১</TableCell>
                  <TableCell className={classes.tableHeader}>Soil Temperature 2 / মাটির তাপমাত্রা ২</TableCell>
                  <TableCell className={classes.tableHeader}>Soil Moisture 1 / মাটির আর্দ্রতা ১</TableCell>
                  <TableCell className={classes.tableHeader}>Soil Moisture 2 / মাটির আর্দ্রতা ২</TableCell>
                  <TableCell className={classes.tableHeader}>Air Temperature / বাতাসের তাপমাত্রা</TableCell>
                  <TableCell className={classes.tableHeader}>Air Moisture / বাতাসের আর্দ্রতা</TableCell>
                  <TableCell className={classes.tableHeader}>Solar Radiation / সূর্যের বিকিরন</TableCell>
                  <TableCell className={classes.tableHeader}>Battery Voltage / ব্যাটারির ভোল্টেজ</TableCell>
                  <TableCell className={classes.tableHeader}>Event Time / ঘটনার সময়</TableCell>
                  <TableCell className={classes.tableHeader}>Next Event / আগামী ঘটনার সময়</TableCell>
                  <TableCell className={classes.tableHeader}>Date / তারিখ</TableCell>
                  <TableCell className={classes.tableHeader}>Irrigation Factor / সেচ গুনক</TableCell>
                  <TableCell className={classes.tableHeader}>Alert / সতর্কতা</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {typeof content.device_data === 'object' && content.device_data.length !== 0 && content.device_data?.map((item, index) => {
                return (
                    <TableRow key={index} className={classes.tableRow}>
                      <TableCell className={classes.tableCell}>{item.device_id}</TableCell>
                      <TableCell className={classes.tableCell}>{item.temperature_1}</TableCell>
                      <TableCell className={classes.tableCell}>{item.temperature_2}</TableCell>
                      <TableCell className={classes.tableCell}>{item.soil_moisture_1}</TableCell>
                      <TableCell className={classes.tableCell}>{item.soil_moisture_2}</TableCell>
                      <TableCell className={classes.tableCell}>{item.air_temperature}</TableCell>
                      <TableCell className={classes.tableCell}>{item.air_moisture}</TableCell>
                      <TableCell className={classes.tableCell}>{item.solar_radiation}</TableCell>
                      <TableCell className={classes.tableCell}>{item.battery_voltage}</TableCell>
                      <TableCell className={classes.tableCell}>{item.timestamp}</TableCell>
                      <TableCell className={classes.tableCell}>{item.next_event}</TableCell>
                      <TableCell style={{ whiteSpace: 'nowrap', width: 'auto', padding: '8px' }} className={classes.tableCell}>
                        {convertDate(item.created_on)}
                      </TableCell>
                      <TableCell className={classes.tableCell}>{item.irrigation_factor}</TableCell>
                      <TableCell className={classes.tableCell}>
                        {item.alert === 'Red' && <ErrorIcon className={`${getAlertColor(item.alert)}`} />}
                        {item.alert === 'Orange' && <WarningIcon className={`${getAlertColor(item.alert)}`} />}
                        {item.alert === 'Yellow' && <InfoIcon className={`${getAlertColor(item.alert)}`} />}
                        {item.alert === 'Green' && <CheckCircleIcon className={`${getAlertColor(item.alert)}`} />}
                      </TableCell>
                    </TableRow>
                  )
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          {typeof content.device_data === 'object' && content.device_data.length !== 0 ? (
          <Box mt={4}>
            <Typography variant="h6" gutterBottom>Plots</Typography>
            {Array.from(new Set(content.device_data.map(device => device.device_id))).map((deviceId, index) => {
              const filteredData = content.device_data.filter(device => device.device_id === deviceId);
              const allValues = filteredData.reduce((acc, entry) => {
                Object.keys(entry).forEach(key => {
                  if (key !== 'timestamp' && key !== 'next_event' && key !== 'created_on' && key !== 'device_id') {
                    acc.push(parseFloat(entry[key]));
                  }
                });
                return acc;
              }, []);
              const maxY = Math.max(...allValues);
              const minY = Math.min(...allValues) >= 0 ? 0 : Math.min(...allValues);

              return (
                <Box key={index} mt={4}>
                  <Typography variant="subtitle1" gutterBottom>Node: {deviceId} </Typography>
                  <ResponsiveContainer width="100%" height={450}>
                    <LineChart data={filteredData} margin={{ top: 20, right: 20, bottom: 130, left: 0 }}>
                      <XAxis dataKey="created_on" tickFormatter={formatDateTime} tick={{ angle: -45, textAnchor: 'end' }} />
                      <YAxis domain={[minY, maxY]} />
                      <CartesianGrid stroke="#f5f5f5" />
                      <Tooltip />
                      <Legend 
                        align="center" 
                        verticalAlign="top" 
                        layout="horizontal" 
                        wrapperStyle={{ paddingBottom: '20px' }}>
                      </Legend>
                      <Line type="monotone" dataKey="air_temperature" stroke="#ff7300" name="Air Temperature" />
                      <Line type="monotone" dataKey="air_moisture" stroke="#0088ff" name="Air Moisture" />
                      <Line type="monotone" dataKey="battery_voltage" stroke="#00C49F" name="Battery Voltage" />
                      <Line type="monotone" dataKey="temperature_1" stroke="#ff0000" name="Soil Temperature 1" /> 
                      <Line type="monotone" dataKey="temperature_2" stroke="#00ff00" name="Soil Temperature 2" /> 
                      <Line type="monotone" dataKey="soil_moisture_1" stroke="#ff00ff" name="Soil Moisture 1" /> 
                      <Line type="monotone" dataKey="soil_moisture_2" stroke="#000000" name="Soil Moisture 2" /> 
                      <Line type="monotone" dataKey="solar_radiation" stroke="#3342FF" name="Solar Radiation" /> 
                    </LineChart>
                  </ResponsiveContainer>
                </Box>
              );
            })}
          </Box>
        ) : null }

        </>
      )}
      <ToastContainer />
    </Box>
  );
};

export default Home;
