import React, { useState, useEffect } from "react";
import AuthService from "../services/auth.service";
import { CropService } from '../services/user.service';
import { Card, CardContent, Typography, List, ListItem, ListItemText, TextField, ListItemIcon, Divider, makeStyles, Select, MenuItem, Button, Grid, IconButton, FormControl, InputLabel } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { Email, PermIdentity, Call, Home, Edit as EditIcon } from "@material-ui/icons";
import AgricultureIcon from '@mui/icons-material/Agriculture';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  card: {
    width: '100%',
    maxWidth: 800,
    padding: theme.spacing(2),
    boxShadow: theme.shadows[5],
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  title: {
    fontWeight: 600,
    fontSize: theme.typography.h5.fontSize,
    [theme.breakpoints.down('xs')]: {
      fontSize: theme.typography.h6.fontSize,
    },
  },
  editButton: {
    color: theme.palette.primary.main,
  },
  divider: {
    marginBottom: theme.spacing(2),
  },
  gridContainer: {
    marginTop: theme.spacing(2),
  },
  saveButton: {
    marginTop: theme.spacing(2),
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(2),
  },
}));


const Profile = () => {
  const currentUser = AuthService.getCurrentUser() || { crop: {} };
  const classes = useStyles();
  const [selectedSeason, setSelectedSeason] = useState(currentUser?.crop?.season || 'NA');
  const [selectedCrop, setSelectedCrop] = useState(currentUser?.crop?.crop || 'NA');
  const [selectedSoil, setSelectedSoil] = useState(currentUser?.crop?.soil || 'NA');
  const [testingLocation, setTestingLocation] = useState(currentUser?.crop?.testing_location || '');
  const [cropData, setCropData] = useState({
    soil: currentUser?.crop?.soil || '',
    season: currentUser?.crop?.season || '',
    crop: currentUser?.crop?.crop || '',
    testing_location: currentUser?.crop?.testing_location || '',
    updated_at: currentUser?.crop?.updated_at || '',
  });

  console.log(currentUser)
  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem('token')) {
      navigate('/login');
    } else {
      const user = JSON.parse(localStorage.getItem('user_data'));
      if (user && user.crop) {
        setCropData(user.crop);
        setSelectedSeason(user?.crop?.season);
        setSelectedCrop(user?.crop?.crop);
        setSelectedSoil(user?.crop?.soil);
      }
    }
  }, [navigate]);

  const handleSeasonChange = (event) => {
    const selectedSeason = event.target.value;
    setSelectedSeason(selectedSeason);
    setSelectedCrop(''); // Reset crop selection
  };

  const handleCropChange = (event) => {
    setSelectedCrop(event.target.value);
  };

  const handleSoilChange = (event) => {
    setSelectedSoil(event.target.value);
  };

  const handleTestingLocationChange = (event) => {
    setTestingLocation(event.target.value);
  };

  const handleSaveCorp = () => {
    const updatedCropData = { soil: selectedSoil, season: selectedSeason, crop: selectedCrop, testing_location: testingLocation };
    CropService.saveCrop(updatedCropData).then(
      (response) => {
        const updatedUser = { ...currentUser, crop: updatedCropData };
        localStorage.setItem("user_data", JSON.stringify(updatedUser)); // Save updated user data to localStorage
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: false
        });
      },
      (error) => {
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
        toast.error(error.response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: false
        });
      }
    );
  };

  const getCropsForSeason = (season) => {
    const seasonData = currentUser.crop_menu.season_and_crop.find(s => s.season === season);
    return seasonData ? seasonData.crop : [];
  };

  let dateData = cropData?.updated_at || localStorage.getItem('user_data_updated_at');

  const isValidDate = (date) => {
    return date instanceof Date && !isNaN(date);
  };

  const formattedDate = isValidDate(new Date(dateData)) ? new Date(dateData).toLocaleString(undefined, { dateStyle: 'short', timeStyle: 'short' }) : "";
  
  return (
    <div className={classes.container}>
      <Card variant="outlined" className={classes.card}>
        <CardContent>
          <div className={classes.header}>
            <Typography variant="h5" component="h2" className={classes.title}>
              {currentUser.username} Profile / প্রোফাইল
            </Typography>
            {/* <IconButton className={classes.editButton} disabled onClick={() => navigate('/edit-profile')}>
              <EditIcon />
            </IconButton> */}
          </div>
          <Divider className={classes.divider} />
          <List>
            <ListItem>
              <ListItemIcon>
                <PermIdentity />
              </ListItemIcon>
              <ListItemText primary={`Name / নাম: ${currentUser.name}`} />
              <ListItemText primary={`Aadhar Number / আধার সংখ্যা: ${currentUser.aadhar_number}`} />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <Call />
              </ListItemIcon>
              <ListItemText primary={`Mobile / মোবাইল: ${currentUser.mobile}`} />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <Email />
              </ListItemIcon>
              <ListItemText primary={`Email / ইমেইল: ${currentUser.email}`} />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <Home />
              </ListItemIcon>
              <ListItemText primary={`Address / ঠিকানা: ${currentUser.address}, ${currentUser.block}, ${currentUser.village}, ${currentUser.district}, ${currentUser.pin_code}, ${currentUser.state}`} />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <AgricultureIcon />
              </ListItemIcon>
              <Grid container spacing={2} className={classes.gridContainer}>
                <Grid item xs={12} sm={6}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel>Season / মরসুম</InputLabel>
                    <Select
                      value={selectedSeason}
                      onChange={handleSeasonChange}
                      label="Season"
                    >
                      {currentUser.crop_menu.season_and_crop.map((seasonData, index) => (
                        <MenuItem key={index} value={seasonData.season}>
                          {seasonData.season}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel>Crop / শস্য</InputLabel>
                    <Select
                      value={selectedCrop}
                      onChange={handleCropChange}
                      label="Crop"
                      disabled={!selectedSeason}
                    >
                      {getCropsForSeason(selectedSeason).map((crop, index) => (
                        <MenuItem key={index} value={crop}>
                          {crop}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                <FormControl variant="outlined" fullWidth>
                    <InputLabel>Soil / মাটি</InputLabel>
                    <Select
                      value={selectedSoil}
                      onChange={handleSoilChange}
                      label="Soil"
                    >
                      {currentUser.crop_menu.soil.map((soilType, index) => (
                        <MenuItem key={index} value={soilType}>
                          {soilType}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                <TextField
                    variant="outlined"
                    fullWidth
                    label="Testing Location / পরীক্ষার ঠিকানা"
                    value={testingLocation}
                    onChange={handleTestingLocationChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <ListItemText primary={<Typography variant="subtitle1">Updated At / আপডেট করা হয়েছে:</Typography>} secondary={formattedDate} />
                </Grid>
              </Grid>
            </ListItem>
          </List>
        </CardContent>
        <div className={classes.footer}>
          <Button variant="contained" color="primary" onClick={handleSaveCorp} className={classes.saveButton}>
            Save / সংরক্ষণ
          </Button>
          <ToastContainer />
        </div>
      </Card>

      {/* <SeasonCropCard seasonAndCrop={currentUser.crop_menu.season_and_crop} /> */}
    </div>
  );
};

export default Profile;