import React, { useState, useEffect } from "react";
import { Routes, Route, Link, Navigate, useNavigate } from "react-router-dom";
import { AppBar, Toolbar, Typography, Button, IconButton, Drawer, List, ListItem, ListItemText } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import AuthService from "./services/auth.service";
import Login from "./components/Login";
import { makeStyles } from '@material-ui/core/styles';
import Register from "./components/Register";
import Home from "./components/Home";
import Vizualize from "./components/Visualize";
import Alert from "./components/Alert";
import Profile from "./components/Profile";
import EventBus from "./common/EventBus";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Csir from '../src/image/CSIR-Logo.jpg';
import Footer from './components/Footer';

const useStyles = makeStyles((theme) => ({
  link: {
    color: 'inherit',
    textDecoration: 'none',
    '&:hover': {
      color: 'Blue',
      backgroundColor: 'white'
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  drawer: {
    width: 250,
  },
  toolbar: {
    justifyContent: 'space-between',
  },
  desktopItems: {
    display: 'flex',
    alignItems: 'center',
  },
  content: {
    paddingTop: theme.spacing(8),
    marginBottom: theme.spacing(8),  // Adjust according to the height of the AppBar
  },
  mobilecontent: {
    paddingTop: theme.spacing(8),
    marginBottom: theme.spacing(12),
  },
  logo: {
    marginRight: theme.spacing(2),
    height: 45, // Adjust the height as needed
    width: 'auto',
    cursor: 'pointer'
  },
}));

const App = () => {
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState(undefined);
  const [mobileOpen, setMobileOpen] = useState(false);
  const classes = useStyles();
  const isMobile = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    const user = AuthService.getCurrentUser();

    if (user) {
      setCurrentUser(user);
    } else {
      setCurrentUser(null);
    }

    EventBus.on("logout", () => {
      logOut();
    });

    return () => {
      EventBus.remove("logout");
    };
  }, []);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const logOut = () => {
    AuthService.logout();
    setCurrentUser(null);
    navigate("/login"); 
  };

  return (
    <div>
      <AppBar position="fixed">
        <Toolbar className={classes.toolbar}>
          <Typography variant="h6">
          <img src={Csir} alt="CSIR Logo" className={classes.logo} onClick={() => navigate("/home")} />
            Crop Monitor
          </Typography>
          <div className={classes.desktopItems}>
            {currentUser ? (
              <>
                {!isMobile && (
                  <>
                    <Button color="inherit" component={Link} className={classes.link} to="/home">
                      Device Data
                    </Button>
                    <Button color="inherit" component={Link} className={classes.link} to="/alert">
                      Alert Data
                    </Button>
                    <Button color="inherit" component={Link} className={classes.link} to="/visualize">
                      Visualize Data
                    </Button>
                    <Button color="inherit" component={Link} className={classes.link} to="/profile">
                      Profile
                    </Button>
                    <Button color="inherit" onClick={logOut}>
                      Logout
                    </Button>
                  </>
                )}
              </>
            ) : (
              <>
                {!isMobile && (
                  <>
                    <Button color="inherit" component={Link} className={classes.link} to="/login">
                      Login
                    </Button>
                    <Button color="inherit" component={Link} className={classes.link} to="/register">
                      Sign Up
                    </Button>
                  </>
                )}
              </>
            )}
            <IconButton
              edge="end"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
              onClick={handleDrawerToggle}
            >
              <MenuIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        anchor="left"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        classes={{ paper: classes.drawer }}
      >
        <List>
          {currentUser ? (
            <>
              <ListItem button component={Link} to="/home" onClick={handleDrawerToggle}>
                <ListItemText primary="Device Data" />
              </ListItem>

              <ListItem button component={Link} to="/alert" onClick={handleDrawerToggle}>
                <ListItemText primary="Alert Data" />
              </ListItem>
              <ListItem button component={Link} to="/visualize" onClick={handleDrawerToggle}>
                <ListItemText primary="Visualize Data" />
              </ListItem>
              <ListItem button component={Link} to="/profile" onClick={handleDrawerToggle}>
                <ListItemText primary="Profile" />
              </ListItem>
              <ListItem button onClick={logOut}>
                <ListItemText primary="Logout" />
              </ListItem>
            </>
          ) : (
            <>
              <ListItem button component={Link} to="/login" onClick={handleDrawerToggle}>
                <ListItemText primary="Login" />
              </ListItem>
              <ListItem button component={Link} to="/register" onClick={handleDrawerToggle}>
                <ListItemText primary="Sign Up" />
              </ListItem>
            </>
          )}
        </List>
      </Drawer>
      <div className={isMobile ? classes.mobilecontent : classes.content}>
        <div className="container-fluid">
          <Routes>
            <Route
              path="/"
              element={currentUser ? <Navigate to="/home" replace /> : <Navigate to="/login" replace />}
            />
            <Route
              path="/home"
              element={currentUser ? <Home /> : <Navigate to="/login" replace />}
            />
             <Route
              path="/alert"
              element={currentUser ? <Alert /> : <Navigate to="/login" replace />}
            />
             <Route
              path="/visualize"
              element={currentUser ? <Vizualize /> : <Navigate to="/login" replace />}
            />
            <Route
              path="/login"
              element={!currentUser ? <Login /> : <Navigate to="/home" replace />}
            />
            <Route
              path="/register"
              element={!currentUser ? <Register /> : <Navigate to="/home" replace />}
            />
            <Route path="/profile" element={<Profile />} />
          </Routes>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default App;
