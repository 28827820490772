// src/components/Footer.js
import React from 'react';
import '../App.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <p>Designed & Developed by CSIR-CMERI.</p>
      </div>
    </footer>
  );
};

export default Footer;