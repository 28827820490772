import axios from "axios";


const API_URL = "https://api.cropmonitor.co.in/v1/";

const getPublicContent = () => {
  return axios.get(API_URL + "sensor-data", {
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem("token")
    }
  });
};
const UserService = {
  getPublicContent
}

const getGatewayData = () => {
  return axios.get(API_URL + "gateway", {
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem("token")
    }
  });
};
const GatewayService = {
  getGatewayData
}

const getAlertsData = () => {
  return axios.get(API_URL + "alerts", {
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem("token")
    }
  });
};
const AlertService = {
  getAlertsData
}

const getVisualizeData = () => {
  return axios.get(API_URL + "visualize", {
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem("token")
    }
  });
};
const VisualizeService = {
  getVisualizeData
}

const controlPump = (state) => {
  return axios.post(API_URL + "pump" + "/" + state, null, {
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem("token")
    }
  });
};
const PumpService = {
  controlPump
}

const saveCrop = (crop) => {
  return axios.patch(API_URL + "crop", {...crop}, {
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem("token")
    }
  });
};
const CropService = {
  saveCrop
}

export { UserService, GatewayService, PumpService, CropService, AlertService, VisualizeService };
