import React, { useState, useEffect } from "react";
import { AlertService } from '../services/user.service';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Box,
  CircularProgress,
  Typography
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import WarningIcon from '@material-ui/icons/Warning';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const useStyles = makeStyles((theme) => ({
  tableHeader: {
    backgroundColor: '#1976d2 !important',
    color: 'white !important',
    fontWeight: 'bold !important',
    position: 'sticky',
    top: 0,
    zIndex: 1,
  },
  tableCell: {
    padding: theme.spacing(2),
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
  },
  tableRow: {
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
  tableContainer: {
    maxHeight: 'calc(100vh - 160px)', // Adjust the max height as needed
    overflowY: 'auto',
  },
  alertRed: {
    color: 'red !important',
    fontWeight: 'bold !important',
  },
  alertOrange: {
    color: 'orange !important',
    fontWeight: 'bold !important',
  },
  alertYellow: {
    color: 'yellow !important',
    fontWeight: 'bold !important',
  },
  alertGreen: {
    color: 'green !important',
    fontWeight: 'bold !important',
  },
  tableContainer1: {
    maxHeight: 'calc(100vh - 160px)', // Adjust the max height as needed
    overflowY: 'auto',
    marginBottom: '1rem'
  },
  chartBox: {
    marginBottom: theme.spacing(4),
    width: '100%', // Added for mobile view
    [theme.breakpoints.up('md')]: {
      width: '50%', // Two charts per row on desktop
    },
  },
}));

const Alert = () => {

  const [alertContent, setAlertContent] = useState("");
  const [alertLoading, setAlertLoading] = useState(true);
  const classes = useStyles();
  const isDesktop = useMediaQuery('(min-width:960px)');

  const navigate = useNavigate();

  const getAlertColor = (alert) => {
    switch (alert) {
      case 'Red':
        return classes.alertRed;
      case 'Orange':
        return classes.alertOrange;
      case 'Yellow':
        return classes.alertYellow;
      case 'Green':
        return classes.alertGreen;
      default:
        return '';
    }
  };

  useEffect(() => {
    if(!localStorage.getItem("token")){
      navigate("/login");
    }
    else{
      AlertService.getAlertsData().then(
        (response) => {
          setAlertContent(response.data);
          setAlertLoading(false);
        },
        (error) => {
          const _content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
            setAlertContent(_content);
            setAlertLoading(false);
          toast.error(error.response.data.message, {
            position: toast.POSITION.TOP_LEFT,
            autoClose: false
          });
      });
    }
  }, []);

  return (
    <Box p={2}>
      {alertLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
          <CircularProgress />
        </Box>
      ) : (
        <>
          <TableContainer component={Paper} className={classes.tableContainer1}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableHeader}>Gateway ID / গেটওয়ের আইডি</TableCell>
                  <TableCell className={classes.tableHeader}>Node ID / নোডের আইডি</TableCell>
                  <TableCell className={classes.tableHeader}>Irrigation Factor / সেচ গুনক</TableCell>
                  <TableCell className={classes.tableHeader}>Alert Time / সতর্কতার সময়</TableCell>
                  <TableCell className={classes.tableHeader}>Alert / সতর্কতা</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {typeof alertContent.data === 'object' && alertContent.data.alerts.length !== 0 && alertContent.data.alerts?.map((item, index) => {
                return (
                    <TableRow key={index} className={classes.tableRow}>
                    <TableCell className={classes.tableCell}>{item.gateway_id}</TableCell>
                    <TableCell className={classes.tableCell}>{item.device_id}</TableCell>
                    <TableCell className={classes.tableCell}>{item.irrigation_factor}</TableCell>
                    <TableCell className={classes.tableCell}>{new Date(item.alert_time).toLocaleString(undefined, { dateStyle: 'short', timeStyle: 'short' })}</TableCell>
                      <TableCell className={classes.tableCell}>
                        {item.alert === 'Red' && <ErrorIcon className={`${getAlertColor(item.alert)}`} />}
                        {item.alert === 'Orange' && <WarningIcon className={`${getAlertColor(item.alert)}`} />}
                        {item.alert === 'Yellow' && <InfoIcon className={`${getAlertColor(item.alert)}`} />}
                        {item.alert === 'Green' && <CheckCircleIcon className={`${getAlertColor(item.alert)}`} />}
                      </TableCell>
                    </TableRow>
                  )
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
      <ToastContainer />
    </Box>
  );
};

export default Alert;
